/*
export default {
  apiKey: 'AIzaSyCuEChuWLEz5BVAZPllrd5l1j72j16xZ2M',
  authDomain: 'sqix-practice-335810.firebaseapp.com',
  projectId: 'sqix-practice-335810',
  storageBucket: 'sqix-practice-335810.appspot.com',
  messagingSenderId: '235088834864',
  appId: '1:235088834864:web:d60b60436d23338ea918f5'
}
*/
export default {
  apiKey: 'AIzaSyAa9avik_B5IvAIpjXoohJi8iaedSOSPDo',
  authDomain: 'esports-social-media.firebaseapp.com',
  projectId: 'esports-social-media',
  storageBucket: 'esports-social-media.appspot.com',
  messagingSenderId: '856303724790',
  appId: '1:856303724790:web:ba15b486a47f4f2adb64d7',
  measurementId: 'G-XXCS0VZ3BW'
}
